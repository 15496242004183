import { Middleware } from 'redux';

import authService from 'services/auth/auth';

import { ON_RESET_EVENT } from './actions';
import { persistor } from './store';
import { RootState } from './types';

// eslint-disable-next-line @typescript-eslint/ban-types
export const onResetEventMiddleware: Middleware<{}, RootState> = () => (next) => async (action) => {
    if (action.type === ON_RESET_EVENT) {
        try {
            await persistor.purge();
            authService.signOut();
            authService.clearCognito();
        } catch {}
        const redirectPath = action.payload;
        window.location.assign(redirectPath);
        return;
    }
    return next(action);
};
