import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_LANGAGE } from 'config/constants';
import { pushDataLayer, pushUserIDToDataLayer } from 'services/gtm';
import { DataLayerKeys } from 'services/gtm/types';

import {
    answerOtpActionAsync,
    associateAuthActionAsync,
    clearStateAsync,
    initializeAuthStateActionAsync,
    initializeUnauthStateActionAsync,
    resumeApplicationActionAsync,
    saveAndExitActionAsync,
    saveApplicantPropertiesActionAsync,
    saveApplicantPropertiesInStateActionAsync,
    saveMetadataPropertiesActionAsync,
    signInActionAsync,
    signOutActionAsync,
    signUpActionAsync,
    startContinueFlowActionAsync,
    submitApplicationActionAsync,
    verifyApplicantActionAsync,
} from './actions';
import { IUserState, TIDVerificationFlow } from './types';

const initialState: IUserState = {
    // Added for the new unauth flow
    altFlow: null,
    applicant: {
        languagePreference: DEFAULT_LANGAGE,
    },
    authId: null,
    creditCardType: null,
    digitalVerificationSent: null,
    error: null,
    // final submission related
    hasSubmittedApplication: false,
    idVerificationFlowSelected: null,
    inContinueFlow: false,
    isAuthenticated: false,
    manualVerificationResult: null,
    status: 'idle',
    submissionStatus: null,
    unauthId: null,
};

export const userSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(initializeAuthStateActionAsync.pending, (state, action) => {
            if (action.meta.arg) {
                state.applicant.languagePreference = action.meta.arg;
            }
        });
        /**
         * Sign In
         */
        builder.addCase(signInActionAsync.pending, (state) => {
            state.status = 'loading';
            state.error = null;
            state.isAuthenticated = false;
        });
        builder.addCase(signInActionAsync.fulfilled, (state) => {
            state.status = 'succeeded';
        });
        builder.addCase(signInActionAsync.rejected, (state, action) => {
            state.error = action.error;
            state.status = 'failed';
        });

        /**
         * Sign Up
         */
        builder.addCase(signUpActionAsync.pending, (state) => {
            state.status = 'loading';
            state.error = null;
            state.isAuthenticated = false;
        });
        builder.addCase(signUpActionAsync.fulfilled, (state, action) => {
            /* We need contact to signUp/signIn in case of user refreshing tab before otp
               AND 
               we don't have email till sac/resume is complete.
               This is why we need to store the contact in state. */
            state.applicant.contact = action.meta.arg.email_or_phone;
            state.status = 'succeeded';
        });
        builder.addCase(signUpActionAsync.rejected, (state, action) => {
            state.error = action.error;
            state.status = 'failed';
        });

        /**
         * Answer OTP
         */
        builder.addCase(answerOtpActionAsync.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });
        builder.addCase(answerOtpActionAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isAuthenticated = action.payload.isAuthenticated; // CognitoUser
        });
        builder.addCase(answerOtpActionAsync.rejected, (state, action) => {
            state.error = action.error;
            state.status = 'failed';
        });

        /**
         * Sign Out
         */
        builder.addCase(signOutActionAsync.fulfilled, (state) => {
            state.error = null;
            state.status = 'succeeded';
            state.isAuthenticated = false;
        });

        /**
         * Save Initial details to state
         */
        builder.addCase(initializeUnauthStateActionAsync.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(initializeUnauthStateActionAsync.rejected, (state) => {
            state.status = 'failed';
        });
        builder.addCase(initializeUnauthStateActionAsync.fulfilled, (state, action) => {
            state.altFlow = action.payload.altFlow;
            state.unauthId = action.payload.unauthId;
            state.status = 'succeeded';
            // Add the cognito ID to GTM to support tracking of affiliates
            pushUserIDToDataLayer(action.payload.unauthId);
            pushDataLayer(DataLayerKeys.altFlow, action.payload.altFlow);
        });

        /**
         * Continue an Application
         */
        builder.addCase(startContinueFlowActionAsync.fulfilled, (state) => {
            state.inContinueFlow = true;
        });

        //if error then clear user state
        builder.addCase(saveAndExitActionAsync.rejected, (state) => ({
            ...initialState,
            applicant: { languagePreference: state.applicant.languagePreference },
        }));

        builder.addCase(saveAndExitActionAsync.fulfilled, (state) => ({
            ...initialState,
            applicant: { languagePreference: state.applicant.languagePreference },
        }));

        /**
         * Save and Continue
         */
        builder.addCase(saveApplicantPropertiesInStateActionAsync.fulfilled, (state, action) => {
            state.applicant = { ...state.applicant, ...action.payload };
            state.status = 'succeeded';
        });
        builder.addCase(saveApplicantPropertiesInStateActionAsync.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(saveApplicantPropertiesInStateActionAsync.rejected, (state) => {
            state.status = 'failed';
        });

        /**
         * Additional auth user / additional insurance
         */
        builder.addCase(saveApplicantPropertiesActionAsync.fulfilled, (state, action) => {
            state.applicant = { ...state.applicant, ...action.payload };
            state.status = 'succeeded';
        });
        builder.addCase(saveApplicantPropertiesActionAsync.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(saveApplicantPropertiesActionAsync.rejected, (state) => {
            state.status = 'failed';
        });

        builder.addCase(resumeApplicationActionAsync.fulfilled, (state, action) => {
            const retrievedState = action.payload.state;
            state.applicant = {
                ...state.applicant,
                ...retrievedState.applicant,
            };
            state.altFlow = retrievedState.altFlow;
            state.manualVerificationResult = retrievedState.manualVerificationResult;
            state.digitalVerificationSent = retrievedState.digitalVerificationSent;
            state.hasSubmittedApplication = retrievedState.hasSubmittedApplication;
            state.submissionStatus = retrievedState.submissionStatus;
            state.unauthId = retrievedState.unauthId;
            state.authId = retrievedState.authId ?? null;
            state.creditCardType = retrievedState.creditCardType ?? null;
            state.hasFailedOtp = retrievedState.hasFailedOtp;
            state.walmartReturnUrl = retrievedState.walmartReturnUrl;
            state.offerCode = retrievedState.offerCode;
            pushUserIDToDataLayer(retrievedState.unauthId);

            pushDataLayer(DataLayerKeys.altFlow, retrievedState.altFlow);
            if (retrievedState.hasFailedOtp) {
                pushDataLayer(DataLayerKeys.otpFailed, true);
            }
            if (retrievedState.applicant.statementPreference) {
                pushDataLayer(DataLayerKeys.paperless, retrievedState.applicant.statementPreference === 'online');
            }
            if (retrievedState.applicant.additionalCardHolderFirstName) {
                pushDataLayer(DataLayerKeys.additionalCardHolder, true);
                // Updating only for Alt A as insurance is the last screen for Alt B
                if (retrievedState.altFlow === 'AltA' && retrievedState.applicant.balanceProtection !== undefined) {
                    // TODO: Add ewp when implemented
                    pushDataLayer(DataLayerKeys.balanceProtection, retrievedState.applicant.balanceProtection);
                }
            }
        });
        builder.addCase(saveMetadataPropertiesActionAsync.fulfilled, (state, action) => {
            state.hasFailedOtp = action.payload.hasFailedOtp;
            state.walmartReturnUrl = action.payload.walmartReturnUrl;
        });
        builder.addCase(associateAuthActionAsync.fulfilled, (state, action) => {
            state.authId = action.payload.authUuid;
        });

        /**
         * Verify
         */
        builder.addCase(verifyApplicantActionAsync.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });
        builder.addCase(verifyApplicantActionAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.manualVerificationResult = action.payload?.data.verified ? 'pass' : 'fail';
        });
        builder.addCase(verifyApplicantActionAsync.rejected, (state, action) => {
            state.error = action.error;
            state.status = 'failed';
        });

        /**
         * Submit Application
         */
        builder.addCase(submitApplicationActionAsync.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });
        builder.addCase(submitApplicationActionAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.submissionStatus = action.payload.status;
            if (action.payload.status === 'Approved') {
                state.creditCardType = action.payload.ccType;
            }
        });
        builder.addCase(submitApplicationActionAsync.rejected, (state, action) => {
            state.error = action.error;
            state.status = 'failed';
        });

        /**
         * Clear State
         */
        builder.addCase(clearStateAsync.fulfilled, (state) => ({
            ...initialState,
            applicant: { languagePreference: state.applicant.languagePreference },
        }));
    },
    initialState,
    name: 'user',
    reducers: {
        load: (state, action: PayloadAction<{ isAuthenticated: boolean }>) => {
            state.isAuthenticated = action.payload.isAuthenticated;
            state.error = null;
            state.status = 'idle';
        },
        setContinueToken: (state: IUserState, action: PayloadAction<string>) => {
            state.continueToken = action.payload;
        },
        setDigitalVerificationSent: (state: IUserState) => {
            state.digitalVerificationSent = true;
        },
        setHasSubmittedApplication: (state: IUserState) => {
            state.hasSubmittedApplication = true;
        },
        setIDVerificationFlow: (state: IUserState, action: PayloadAction<TIDVerificationFlow>) => {
            state.idVerificationFlowSelected = action.payload;
        },
    },
});
