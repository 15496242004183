import { EnvEnum } from '@fairstone-frontend/utils/core/types';

import { AppContextEnum } from 'utils/context/types';

export const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT || '';
export const CONTENTFUL_API_URL = process.env.REACT_APP_CONTENTFUL_API_URL || '';
export const API_URL = process.env.REACT_APP_API_URL || '';
export const CONTENTFULL_ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || '';
export const CANADA_POST_KEY = process.env.REACT_APP_CANADA_POST_KEY || '';
export const MERCHANT_IMAGES_URL = process.env.REACT_APP_MERCHANT_IMAGES_URL || '';
export const PUBLIC = process.env.PUBLIC_URL;
export const GTM_ID = process.env.REACT_APP_GTM_ID || '';
export const LOGROCKET_APPNAME = process.env.REACT_APP_LOGROCKET_APPNAME || '';
export const TRACKJS_APPNAME = process.env.REACT_APP_TRACKJS_APPNAME || '';
export const TRACKJS_KEY = process.env.REACT_APP_TRACKJS_KEY || '';
export const CURRENT_ENV = process.env.REACT_APP_CURRENT_ENV
    ? (process.env.REACT_APP_CURRENT_ENV as EnvEnum)
    : EnvEnum.LOCAL;
export const APP_CONTEXT = process.env.REACT_APP_CONTEXT || AppContextEnum.WMT;
export const REGION = process.env.REACT_APP_CURRENT_REGION || 'us-east-1';
export const US_EAST_1 = 'us-east-1';
export const CA_CENTRAL_1 = 'ca-central-1';
export const THEME_API_URL = process.env.REACT_APP_THEME_API_URL || '';
export const DEFAULT_LANGAGE = window.location.href.includes('/fr') ? 'fr' : 'en';

export const COGNITO_IDENTITY_POOL = process.env.REACT_APP_COGNITO_IDENTITY_POOL || '';
export const COGNITO_USER_POOL = process.env.REACT_APP_COGNITO_USER_POOL || '';
export const COGNITO_USER_POOL_WEBCLIENT = process.env.REACT_APP_COGNITO_USER_POOL_WEBCLIENT || '';
export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION || 'ca-central-1';

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';
export const THREAT_METRIX_URL = process.env.REACT_APP_THREAT_METRIX_URL || '';
export const THREAT_METRIX_ORG_ID = process.env.REACT_APP_THREAT_METRIX_ORG_ID || '';
export const ONFIDO_MANUAL_UPLOAD = process.env.REACT_APP_ONFIDO_MANUAL_UPLOAD || false;
export const CMS_WALMART_URL = process.env.REACT_APP_CMS_URL || '';
export const DYNATRACE_URL = process.env.REACT_APP_DYNATRACE_URL || '';
