import { useIntl } from 'react-intl';

import { CMS_WALMART_URL } from 'config/constants';
import { useAppSelector } from 'store/redux/hooks';
import { routerState } from 'store/redux/modules/router';
import { ERequestChannel } from 'utils/constants';
import { removeKeys } from 'utils/query/query';

export interface IUseRedirectToRewardsWalmart {
    getRedirectRewardsWalmartUrl: () => string;
    onRedirectToRewardsWalmart: () => void;
}

export const useRedirectToRewardsWalmart = (): IUseRedirectToRewardsWalmart => {
    const router = useAppSelector(routerState);
    const { locale } = useIntl();

    const getRedirectRewardsWalmartUrl = (): string => {
        const otherParams = removeKeys(router.savedQueryParameters, [
            'vendor',
            'storenum',
            'cashierid',
            'requestChannel',
            'channel',
            'sessionID',
            'lang',
        ]);

        const queryString = new URLSearchParams(otherParams).toString();
        let flowReturn;

        switch (router.requestChannel) {
            case ERequestChannel.Walmart:
                flowReturn = '/walmart';
                break;
            case ERequestChannel.QR:
                flowReturn = '/qr';
                break;
            case ERequestChannel.Associate:
                flowReturn = '/wmassoc';
                break;
            default:
                flowReturn = '';
        }

        const language = locale.includes('en') ? 'en' : 'fr';
        return `${CMS_WALMART_URL}/${language}${flowReturn}${queryString ? `?${queryString}` : ''}`;
    };

    const onRedirectToRewardsWalmart = () => {
        window.location.href = getRedirectRewardsWalmartUrl();
    };

    return { getRedirectRewardsWalmartUrl, onRedirectToRewardsWalmart };
};
