import { useAppSelector } from 'store/redux/hooks';
import { routerState } from 'store/redux/modules/router';
import { userState } from 'store/redux/modules/user';
import { ERequestChannel } from 'utils/constants';
import { removeKeys } from 'utils/query/query';

export interface IUseRedirectToWalmart {
    getRedirectWalmartUrl: () => string;
    onRedirectToWalmart: () => void;
}

export const useRedirectToWalmart = (): IUseRedirectToWalmart => {
    const user = useAppSelector(userState);
    const router = useAppSelector(routerState);

    const getRedirectWalmartUrl = (): string => {
        const otherParams = removeKeys(router.savedQueryParameters, [
            'vendor',
            'storenum',
            'cashierid',
            'requestChannel',
            'channel',
            'sessionID',
            'lang',
        ]);
        const queryString = new URLSearchParams(otherParams).toString();
        const returnUrl =
            ERequestChannel.Walmart === router.requestChannel && user.walmartReturnUrl
                ? `${user.walmartReturnUrl}${user.walmartReturnUrl.includes('?') ? '&' : '?'}${queryString}`
                : 'https://www.walmart.ca';
        return returnUrl;
    };

    const onRedirectToWalmart = () => {
        window.location.href = getRedirectWalmartUrl();
    };

    return { getRedirectWalmartUrl, onRedirectToWalmart };
};
