import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IRouteState } from './types';

const initialState: IRouteState = {
    channel: '',
    requestChannel: '',
    savedQueryParameters: {},
    sessionID: '',
};

export const routerSlice = createSlice({
    initialState,
    name: 'router',
    reducers: {
        /**
         * Clear router state to the initial values
         */
        clearRouterState: () => ({
            ...initialState,
        }),

        /**
         * Save initial query parameters to the state
         */
        saveInitialQueryParams: (state, action: PayloadAction<any>) => {
            state.channel = action.payload.channel;
            state.requestChannel = action.payload.requestChannel;
            state.sessionID = action.payload.sessionID;
            state.savedQueryParameters = action.payload;
        },
    },
});
