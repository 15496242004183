import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@fairstone/ui/core';
import {
    Button,
    // CloseDialogButton,
    DialogActions,
    DialogContent,
    DialogTitle,
    Loading,
    Typography,
} from '@fairstone/ui/core/components';
import { t } from '@fairstone/ui/core/utils/translate';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { useRedirectToRewardsWalmart } from 'hooks/useRedirectToRewardsWalmart/useRedirectToRewardsWalmart';
import { useRedirectToWalmart } from 'hooks/useRedirectToWalmart/useRedirectToWalmart';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import authService from 'services/auth/auth';
import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { clearRouterState, routerState } from 'store/redux/modules/router';
import { saveAndExitActionAsync } from 'store/redux/modules/user';
import { ERequestChannel, ERoutes } from 'utils/constants';

import styles from './SaveAndExit.module.scss';

interface ISaveAndExitProps {
    onClose: () => void;
}

export const SaveAndExit: React.FC<ISaveAndExitProps> = ({ onClose }: ISaveAndExitProps): React.ReactElement => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { requestChannel } = useAppSelector(routerState);
    const [isLoading, setIsLoading] = useState(false);
    const { getRedirectWalmartUrl } = useRedirectToWalmart();
    const { getRedirectRewardsWalmartUrl } = useRedirectToRewardsWalmart();

    const intl = useIntl();
    const locale = intl.locale.split('-')[0];

    const onSubmit = useCallback(async () => {
        try {
            setIsLoading(true);
            //get return url before clearing state or else values are not there
            const returnUrl =
                ERequestChannel.Walmart === requestChannel ? getRedirectWalmartUrl() : getRedirectRewardsWalmartUrl();

            const response = await dispatch(saveAndExitActionAsync({ locale: locale })).unwrap();
            await dispatch(clearRouterState());
            authService.signOut();
            authService.clearCognito();

            if (response?.error) {
                onClose();
                navigate(`/${ERoutes.ERROR}`);
            } else {
                window.location.href = returnUrl;
            }
        } catch (err) {
            TrackJS.track(err!);
            navigate(`/${ERoutes.ERROR}`);
        }
    }, []);

    return (
        <>
            {isLoading && <Loading data-testid="loading" />}
            <Dialog className={styles.dialog} data-testid="save-and-exit-dialog" onClose={onClose} open={true}>
                <DialogTitle onClose={onClose}>
                    <Typography variant="h1"> {t('components.saveAndExit.title')} </Typography>
                </DialogTitle>
                <DialogContent className={styles.content}>
                    <Typography component="p" variant="body3">
                        {t('components.saveAndExit.description1')}
                    </Typography>
                    <Typography component="p" variant="body3">
                        {t('components.saveAndExit.description2')}
                    </Typography>
                </DialogContent>
                <DialogActions className={styles.actions}>
                    <PrimaryButton data-testid="submit" disabled={isLoading} onClick={onSubmit} type="submit">
                        {t('buttons.saveAndExit')}
                    </PrimaryButton>
                    <Button appearance="text" border className={styles.cancel} onClick={onClose} type="reset">
                        {t('buttons.cancelSaveAndExit')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
